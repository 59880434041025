import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux';
import DataTable from 'react-data-table-component';
import {useLocation} from "react-router-dom";
import {get_beneficiary_reimbursement} from "../../helper/Api";
import {AiOutlineEye} from 'react-icons/ai';
import {Link} from 'react-router-dom';
import {useParams} from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import {Tab, Tabs} from 'react-bootstrap';
export default function BeneficiaryReimbursementList(filterData) {
    const {authData} = useSelector((state) => state.authData);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    let {state} = useLocation();
    const [data, setData] = useState(state && state.data ? state.data : []);
    const {listType} = useParams();


    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px"
            },
        },
    };
    let paymentColumns = [{
        name: 'Payment L2',
        selector: (row) => row.l2_payment_approval == null ? "Pending" : row.l2_payment_approval == 1 ? "Approved" : "Rejected",
        wrap: true,
        width: "100px"
    },
    {
        name: 'Payment L3',
        selector: (row) => row.l3_payment_approval == null ? "Pending" : row.l3_payment_approval == 1 ? "Approved" : "Rejected",
        wrap: true,
        width: "100px"
    },];

    if(listType && listType == 'approval') {
        paymentColumns = [];
    }

    const columns = [
        {
            name: 'Actions',
            selector: (row) => {
                return (
                    <>
                    <div className='user_action'>
                        <Link to={`/add-beneficiary-reimbursement/${row.id}`}><AiOutlineEye/></Link>
                        {
                            (authData.user.user_type === 'l1' && (row.l2_approval == 0 || row.l3_approval == 0)) ? <Link to={`/edit-beneficiary-reimbursement/${row.id}`}><FaEdit/></Link>: ''
                        }
                        </div>
                    </>
                )
            },
            wrap: true,
            width: "100px",
            center: true
        },
        {
            name: 'Department',
            selector: (row) => row.department,
            wrap: true,
            width: "150px"
        },
        {
            name: 'Order No',
            selector: (row) => row.reimbursement_order_no,
            wrap: true,
            width: "100px"
        },
        {
            name: 'Order Date',
            selector: (row) => row.reimbursement_order_date,
            wrap: true,
            width: "150px"
        },
        {
            name: 'Type',
            selector: (row) => row.type,
            wrap: true,
            width: "150px"
        },
        {
            name: 'Order Amount',
            selector: (row) => row.reimbursement_order_value,
            wrap: true,
            width: "150px"
        },
        {
            name: 'Financial Year',
            selector: (row) => row.financial_year,
            wrap: true,
            width: "150px"
        },
        {
            name: 'Scheme Code',
            selector: (row) => row.code,
            wrap: true,
            width: "100px"
        },
        {
            name: 'Scheme Name',
            selector: (row) => row.name,
            wrap: true,
            width: "300px"
        },
        {
            name: 'Subhead Code',
            selector: (row) => row.subhead_code,
            wrap: true,
            width: "150px"
        },
        {
            name: 'Subhead Name',
            selector: (row) => row.subhead_name,
            wrap: true,
            width: "300px"
        },
        {
            name: 'Creation Date',
            selector: (row) => row.created_at,
            wrap: true,
            width: "150px"
        },
        {
            name: 'Status L2',
            selector: (row) => row.l2_approval == null ? "Pending" : row.l2_approval == 1 ? "Approved" : "Rejected",
            wrap: true,
            width: "100px"
        },
        {
            name: 'Status L3',
            selector: (row) => row.l3_approval == null ? "Pending" : row.l3_approval == 1 ? "Approved" : "Rejected",
            wrap: true,
            width: "100px"
        },
        ...paymentColumns
    ];

    const handleChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        setRowsPerPage(currentRowsPerPage);
        setCurrentPage(1); // Reset to the first page when rows per page changes
    };

    const fetchChallans = async () => {
        try {
            const resp = await get_beneficiary_reimbursement({
                from_date: filterData?.fromDate || '', 
                to_date: filterData?.toDate || '',
                search_str: filterData?.searchStr || '',
                dateColumn: filterData?.dateColumn || '',
                department_id: filterData?.departmentId || '',
                listType: listType == 'dbt_payment' ? '' : listType,
            });
            if (resp.data.status) {
                setData(resp.data.result);
            }
        } catch (error) {
            setData([]);
        }
    }
    useEffect(() => {
        fetchChallans();
    }, [filterData.fromDate, filterData.toDate, filterData.dateColumn, filterData.searchStr, filterData.departmentId, listType])



    return (
        <div>
            <DataTable
                customStyles={customStyles}
                className="dataTables_wrapper"
                columns={columns}
                data={data}
                fixedHeader
                fixedHeaderScrollHeight="600px"
                pagination
                paginationPerPage={rowsPerPage}
                paginationTotalRows={data.length}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </div>
    )

}