import React, {useEffect, useState} from 'react'
import {get_disbursment_invoices} from '../../helper/Api';
import DataTable from 'react-data-table-component';
import {useDispatch, useSelector} from 'react-redux';
import {Col, Container, Form, Row} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import {AiOutlineEye} from "react-icons/ai";

import {
    GET_D_INVOICES_START,
    GET_D_INVOICES_SUCCESS,
    GET_D_INVOICES_FAIL
} from '../../RTK/Slices/DisbursmentInvoiceSlice';

// import './ManageDisbursment.css';

const columns = [
    {
        name: 'View',
        selector: (row) => <Link to={`/view-invoice-new/${row.id}`}><span><AiOutlineEye/></span></Link>,
        sortable: true,
        wrap: true,
        width: "100px"
    },
    {
        name: 'Benificiary Name',
        selector: (row) => row.company_name,
        sortable: true,
        wrap: true,
        width: "200px"
    },
    {
        name: 'Invoice Date',
        selector: (row) => row.invoice_date,
        sortable: true,
        width: "150px",
        wrap: true,
    },
    {
        name: 'Invoice No.',
        selector: (row) => row.invoice_no,
        width: "150px",
        wrap: true,
        // sortable: true,
    },
    {
        name: 'Payment Type',
        selector: (row) => row.payment_type === '1' ? "FULL PAYMENT" : row.payment_type === '2' ? 'PART PAYMENT' : '',
        // sortable: true,
        wrap: true,
        width: "150px"
    },
    {
        name: 'Invoice Amount',
        selector: (row) => Number(row?.invoice_value),
        sortable: true,
        width: "150px",
        wrap: true,
        center: true,
        // right: true
    },
    {
        name: 'Sanction Amount',
        selector: (row) => Number(row.sanction_amount),
        sortable: true,
        width: "150px",
        wrap: true,
        center: true,
        // right: true
    },
    {
        name: 'Payment Amount',
        selector: (row) => Number(row.payable_amount),
        sortable: true,
        width: "150px",
        wrap: true,
        center: true,
        // right: true
    },
    {
        name: 'Voucher Date',
        selector: (row) => row.voucher_date,
         sortable: true,
        width: "150px",
        wrap: true,
    },
    {
        name: 'Voucher No.',
        selector: (row) => row.voucher_no,
        width: "150px",
        wrap: true,
        sortable: true,
    },
    {
        name: 'Payment Sanction Order Date',
        selector: (row) => row.sanction_order_date,
        width: "200px",
        wrap: true,
        sortable: true,
    },
    {
        name: 'Payment Sanction Order No.',
        selector: (row) => row.sanction_order_no,
        width: "200px",
        wrap: true,
        sortable: true,
    },
    {
        name: 'Transaction Success Date & Time',
        selector: (row) => (row.payment_status == 1 && row.l3_payment_status == 1) ? row.transaction_date : "",
        sortable: true,
        width: "200px",
        wrap: true
    },
    {
        name: 'Actions (L2)',
        selector: (row) => {
            let status = row.payment_status;
            return !row.isFooter ? (
                <b>{status == 1 ? 'COMPLETED' : 'PENDING'}</b>
            ) : <b>Total</b>;
        },
        // sortable: true,
        width: "200px"
    },
    {
        name: 'Actions (L3)',
        selector: (row) => {
            let status = row.l3_payment_status;
            return !row.isFooter ? (
                <b>{status == 1 ? 'COMPLETED' : 'PENDING'}</b>
            ) : ''
        },
        // sortable: true,
        width: "200px"
    },
];

const ManageDisbursment = (filterDate) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const {disbursment_invoices, loading} = useSelector((state) => state.disbursment_invoiceData);
    const {authData} = useSelector((state) => state.authData);
    const dispatch = useDispatch();
    const [data, setData] = useState(disbursment_invoices);

    const fetchInvoices = async () => {
        dispatch(GET_D_INVOICES_START());
        try {
            const invoices_response = await get_disbursment_invoices({
                from: filterDate.fromDate,
                to: filterDate.toDate,
                dateColumn: filterDate.dateColumn,
                search_str: filterDate.searchStr,
                department_id: filterDate.departmentId
            });
            // console.log(invoices_response)
            if (invoices_response.data.status) {
                dispatch(GET_D_INVOICES_SUCCESS(invoices_response.data.list))
                setData(invoices_response.data.list)
            } else {
                dispatch(GET_D_INVOICES_FAIL(invoices_response.data.message))
            }
        } catch (error) {
            dispatch(GET_D_INVOICES_FAIL('something went wrong'))
        }
    }

    useEffect(() => {
        fetchInvoices();
    }, [filterDate.fromDate, filterDate.toDate, filterDate.dateColumn, filterDate.searchStr, filterDate.departmentId])

    useEffect(() => {
        setData(disbursment_invoices)
    }, [disbursment_invoices]);

    const customStyles = {

        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px"
            },
        },
    };
    const calculateTotalAmount = (propertyName) => {
        let total = 0;
        data.forEach((row) => {
            const depositAmount = parseFloat(row[propertyName]);
            if (!isNaN(depositAmount)) {
                total += depositAmount;
            }
        });
        return total.toFixed(2);
    };

    const totalInvoiceAmount = calculateTotalAmount('invoice_value');
    const totalSanctionAmount = calculateTotalAmount('sanction_amount');
    const totalPayableAmount = calculateTotalAmount('payable_amount');

    const handleChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        setRowsPerPage(currentRowsPerPage);
        setCurrentPage(1); // Reset to the first page when rows per page changes
    };

    return (
        <div>
            <DataTable
                customStyles={customStyles}
                className="dataTables_wrapper"
                progressPending={loading}
                columns={columns}
                data={data}
                fixedHeader
                fixedHeaderScrollHeight="600px"
                pagination
                paginationPerPage={rowsPerPage}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <div className="add_new_user">
                <Container fluid>
                    <Row>
                        <Col sm={4} >
                            <p className='float-start'><strong>Total Invoice Amount: {totalInvoiceAmount}</strong> </p><br />
                            <p className='float-start'><strong>Total Sanctioned Amount: {totalSanctionAmount}</strong></p><br />
                            <p className='float-start'><strong>Total Payment Amount: {totalPayableAmount}</strong></p>
                        </Col>
                    </Row>
                </Container>
            </div>
            
        </div>
    )
}

export default ManageDisbursment
