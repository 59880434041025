import React, {useState} from 'react';
import Tools from '../../components/Tools/Tools';
import ManageDisbursment from '../../components/Disbursment/ManageDisbursment';
import ManageDisbursmentHeader from '../../components/Disbursment/ManageDisbursmentHeader';
import {Tab, Tabs} from 'react-bootstrap';
import {Link, useLocation, useParams, useNavigate} from 'react-router-dom';

const InvoiceList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [dateColumn, setDateColumn] = useState('voucher_date');
    const [searchStr, setToSearchStr] = useState('');
    const searchParams = new URLSearchParams(location.search);
    const list=searchParams.get('list');
    const [heading, setHeading] = useState('Invoice-Service');
    const [departmentId, setDepartmentId] = useState('');
    const [tier, setTier] = useState('');


    const handleDateChange = (start, end, dateColumn, searchStr, fyId, tier, departmentId) => {
        setFromDate(start);
        setToDate(end);
        setDateColumn(dateColumn);
        setToSearchStr(searchStr);
        setTier(tier);
        setDepartmentId(departmentId);
    };

    const handleTabSelect = (key) => {
        if (key == 'paymentStatus') {
            setHeading("Invoice Payment");
            navigate('/fund-invoice-list?pending=true');
        } else if(key == 'InvoiceList') {
            setHeading("Invoice Approve");
            navigate('/fund-invoice-list?pending=false');
        } else if (key == 'Invoice-Service') {
            navigate('/invoices?list=true')
        }
    };

    return (
        <div>
            <Tools/>
            {/* heading={heading} */}
            <ManageDisbursmentHeader onDateChange={handleDateChange} startDate={fromDate} endDate={toDate} dateColumnType={dateColumn}  type="Disbursments" />
            <div className='scheme p-2'>
                {
                    list && <div className='all_tabs'>
                    <Tabs
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        activeKey={'Invoice-Service'}
                        onSelect={handleTabSelect}
                    >
                        <Tab eventKey="InvoiceList" title="Invoice Approval" className='p-2'></Tab>
                        <Tab eventKey="paymentStatus" title="Invoice Payment" className='p-2'></Tab>
                        <Tab eventKey="Invoice-Service" title="Vendor/Contract/Invoice/Service Provider Payments" className='p-2'></Tab>
                        <Tab eventKey="Penalty" title="Penalties Approval" className='p-2'></Tab>
                        <Tab eventKey="List-Penalty" title="Penalty Payments" className='p-2'></Tab>
                        <Tab eventKey="Withheld" title="Withheld Amount Payment" className='p-2'></Tab>
                        <Tab eventKey="List-Withheld" title="Withheld Amount Payments" className='p-2'></Tab>
                    </Tabs>
                </div>
                }
                
                <ManageDisbursment fromDate={fromDate} toDate={toDate} dateColumn={dateColumn} searchStr={searchStr} tier={tier} departmentId={departmentId}/>
            </div>
        </div>
    )
}


export default InvoiceList
